<template>
	<div class="m_common_content">
		<div class="m_common_top">
			<div class="m_checkdata_content">
				<el-cascader :options="treeData" :show-all-levels="false" :props="cascaderProps" v-model="cascaderValue" placeholder="请选择行政区域" clearable></el-cascader>
				<el-input v-model="inputValue" placeholder="请输入加盟商名称搜索" clearable></el-input>
				<el-button @click="search" class="m_btn_search">搜索</el-button>
			</div>
		</div>
		<div class="m_common_middle">
			<div class="m_table_content">
				<el-table :data="tableData" stripe>
					<el-table-column label="序号" type="index" width="60" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="userName" label="账号名" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="partnerName" label="加盟商" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="partnerProvince" label="所属省" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="partnerCity" label="所属市" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="partnerLinker" label="联系人" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="partnerTel" label="联系方式" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="partner" label="调价权限" show-overflow-tooltip>
						<template slot-scope="scope">
							<el-switch v-model="scope.row.enabled" active-color="#13ce66" inactive-color="#ff4949" @change="enabledChange(scope.row)">
							</el-switch>
						</template>
					</el-table-column>
					<el-table-column label="状态" show-overflow-tooltip>
						<template slot-scope="scope">
							<i v-if="scope.row.status === 'Valid'" style="color: #62c462">正常</i>
              				<i v-else style="color: #ff4343">禁用</i>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div class="m_common_bottom">
			<m-pagination @pageChange="pageChange" :total="total"></m-pagination>
		</div>
	</div>
</template>
<script>
	import elDragDialog from "@/directive/el-drag-dialog";
	import mPagination from "@/components/pagination/index.vue";
	import { PAGE_SIZE } from "@/utils/config";
	import {
		getlocalpricesEnabled,
		setlocalpricesEnabled,
	} from "@/api/patnerPrice";
	import { regions } from "@/api/base";
	export default {
		directives: {
			elDragDialog
		},
		data() {
			const _this = this;
			return {
				treeData: [],
				cascaderProps: {
					lazy: true,
			        checkStrictly: true,
			        async lazyLoad(node, resolve) {
			          const result = await _this.lazyLoad(node);
			          resolve(result);
        },
				},
				total: 0,
				currentPage: 1,
				inputValue: null,
				cascaderValue: "",
				tableData: [],
			};
		},
		props: {},
		computed: {
			pageSize() {
				return PAGE_SIZE;
			},
		},
		components: {
			mPagination,
		},
		async created() {
			this.select();	
			const res = await this.regions();
			this.treeData = res;
		},
		mounted() {},
		methods: {
			pageChange(page) {
				this.currentPage = page;
				this.select();
			},
			search() {
				this.select();
			},
			enabledChange(data) {
				this.update({
					userId: data.userId,
					partnerId: data.partnerId,
					enabled: data.enabled,
				});
			},
			async select() {
				const res = await getlocalpricesEnabled({
					pageNo: this.currentPage,
					pageSize: this.pageSize,
					name:this.inputValue,
					province: this.cascaderValue && this.cascaderValue[0] ? this.cascaderValue[0] : null,
					city: this.cascaderValue && this.cascaderValue[1] ? this.cascaderValue[1] : null,
					county: this.cascaderValue && this.cascaderValue[2] ? this.cascaderValue[2] : null
				});
				const {
					pageCount,
					results
				} = res;
				this.tableData = results;
				this.total = pageCount.allCount;
			},
			async update(data) {
				const res = await setlocalpricesEnabled(data);
				const {
					pageCount,
					results
				} = res;
				this.$message({
					message: "修改成功",
					type: "success",
				});
				this.select();
				this.dialogVisible = false;
			},
			async regions (data, level) {
				const res = await regions(data);
				const {
					pageCount,
					results
				} = res;
				let list = [];
				results.forEach((item) => {
					list.push({
						value: item,
						label: item,
						leaf: level === 2,
					});
				});
				return list;
			},
			async lazyLoad(node) {
				const {
					level
				} = node;
				let query = null;
				switch(level) {
					case 1:
						query = {
							province: node.path[0],
						};
						break;
					case 2:
						query = {
							province: node.path[0],
							city: node.path[1],
						};
						break;
				}
				if(!query) return;
				const res = await this.regions(query, level);
				return res;
			},
		},
		beforeDestroy() {},
		destroyed() {},
	};
</script>
<style lang="less" scoped>
	@import "../../assets/css/common/common.less";
</style>
<style lang="less">
	@import "../../assets/css/checkdata/checkdata.less";
</style>